<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('double_major_application')" :isFilter="false" :isNewButton="isNewButton"
                    @new-button-click="store()">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('double_major_application')" :isFilter="false" :isNewButton="isNewButton"
                    @new-button-click="store()">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                :unchecked-value="true" v-model="column.hidden" v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>

            <template v-if="!applicationActive && preferences.length < 1">
                <p class="body-1 h6 mb-2">
                    {{ $t("undergraduate_check_date_message") }}
                </p>
            </template>

            <b-card v-else-if="formKeyLength > 0">
                <template #header>
                    <div class="d-flex flex-row align-items-center">
                        <h5 class="mb-0">
                            {{ student.name }} {{ student.surname }}:
                        </h5>
                        <h6 class="ml-4 mb-0 text-secondary">
                            <template v-if="student.result">
                                <b-badge :variant="(student.result == 'placed' ? 'success' : 'danger') + ' text-uppercase'">
                                    {{ $t(`application_result.${student.result}`) }}
                                </b-badge>
                            </template>
                            <template v-else>
                                <template v-if="!student.status">
                                    {{ $t('minor_status_obj.waiting') }}
                                </template>
                                <template v-else-if="student.status == 'waiting_preference'">
                                    {{ $t("application_send_waiting") }}
                                </template>
                                <template v-else-if="student.status == 'published'">
                                    {{ $t("published") }}
                                </template>
                                <template v-else>
                                    {{ $t("your_application_has_been_evaluated") }}
                                </template>
                            </template>

                        </h6>
                    </div>
                </template>
                <b-card-text>
                    <b-row>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('faculty')">
                                <div class="label-as-input">
                                    {{
                                        getObjectValue(student, "faculty_name")
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('program')">
                                <div class="label-as-input">
                                    {{
                                        getObjectValue(student, "program_name")
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('class')">
                                <div class="label-as-input">
                                    {{
                                        student.class
                                        ? $t("class_x", {
                                            class: student.class,
                                        })
                                        : "-"
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('number_of_semesters')">
                                <div class="label-as-input">
                                    {{
                                        getObjectValue(
                                            student,
                                            "semester_count"
                                        )
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('student_number')">
                                <div class="label-as-input">
                                    {{ student.student_number }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('registration_type_1')">
                                <div class="label-as-input">
                                    {{
                                        getLocaleText(
                                            student,
                                            "registration_type_text"
                                        ) ||
                                        getLocaleText(
                                            student.registration_type,
                                            "name"
                                        )
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('registration_date')">
                                <div class="label-as-input">
                                    {{
                                        toLocaleDate(student.registration_date)
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('prep_status')">
                                <div class="label-as-input">
                                    {{
                                        getLocaleText(
                                            student,
                                            "prep_status_text"
                                        ) ||
                                        getLocaleText(
                                            student.prep_status,
                                            "name"
                                        )
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4">
                            <b-form-group :label="$t('gno')">
                                <div class="label-as-input">
                                    {{ getObjectValue(student, "cgpa") }}
                                </div>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6" lg="4"
                            v-if="student.student_program?.student?.person?.nationality_code != 'TR' && student.nationality_code != 'TR'">
                            <b-form-group :label="$t('equivalence_status')">
                                <div class="label-as-input">
                                    {{
                                        student.equivalence_status
                                        ? $t("there_is")
                                        : $t("none")
                                    }}
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-card-text>

                <button class="btn btn-primary" v-if="!student.status" @click="start">
                    {{ $t("double_major_application_start") }}
                </button>
            </b-card>

            <b-alert v-if="errMessage" show variant="secondary"> <span class="body-1">{{ $t('api.' + errMessage) }}</span>
            </b-alert>
            <template v-if="student.status">
                <template v-if="applicationDocuments">
                    <div>
                        <h6 class="mt-4 mb-2">{{ $t('documents') }} </h6>
                    </div>
                    <div class="d-flex flex-wrap">
                        <b-card :sub-title="getLocaleText(doc, 'name')" class="" :class="index > 0 ? 'ml-2' : ''"
                            style="max-width: 20rem;" v-for="(doc, key, index) in applicationDocuments" :key="index">
                            <b-card-text>{{ $t('status') }}: {{ $t(`status_${doc.status}`) }}</b-card-text>
                            <b-card-text v-if="doc.status == 'declined'">{{ $t('delete_file_upload_again') }}</b-card-text>
                            <template #footer>
                                <div class="d-flex flex-row justify-content-around">
                                    <a class="card-link" v-if="doc.status == 'waiting'"
                                        @click="documentModalOpen(key, doc)">
                                        {{ $t('upload') }}
                                    </a>
                                    <a class="card-link" v-else @click="showDoc(doc)">{{ $t('show') }}</a>
                                    <b-link v-if="!['approved', 'waiting'].includes(doc.status)"
                                        class="card-link text-danger" @click="removeDoc(doc.name)">
                                        {{ $t('delete') }}
                                    </b-link>
                                </div>
                            </template>
                        </b-card>
                    </div>
                    <hr />
                </template>

                <template v-if="preferenceOn">
                    <b-row class="py-2">
                        <b-col cols="12" class="mb-4 mt-2" v-if="preferences.length > 0">
                            <h5 class="mb-0">{{ $t("your_preferences") }}</h5>
                        </b-col>
                        <b-col cols="12" class="mb-4" v-if="student.status == 'waiting_preference'">
                            <div class="d-flex align-items-center justify-content-between">
                                <h5 class="mb-0">
                                    {{ getLocaleText(student, "status_text") }}
                                </h5>

                                <button class="btn btn-secondary" @click="editableOn" v-if="preferences.length > 0">
                                    {{
                                        preferenceEditable
                                        ? $t("close_editing")
                                        : $t("preferences_order_change")
                                    }}
                                </button>
                            </div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <!-- <b-col cols="12" :style="{flexWrap:'wrap'}" > -->
                        <template v-for="(preferenceItem, index) in preferences">
                            <doubleMajorPreference @changeRank="changeRank" :preferenceEditable="preferenceEditable"
                                :index="preferenceItem.rank" :value="preferenceItem" @getItems="getPreferences"
                                @updateValue="(e) => (preferences[index] = e)" :key="index" />
                        </template>
                        <!-- </b-col> -->

                        <!-- <draggable v-show="preferenceEditable" v-model="changePreferences" @end="onEnd"
                        class="preference-draggable row">
                        <transition-group>
                            <template v-for="( preferenceItem, index ) in  changePreferences ">
                                <doubleMajorPreference :value="preferenceItem" :index="index + 1" :key="preferenceItem.id" />
                            </template>
                        </transition-group>
                    </draggable> -->
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="mb-4 mt-2 text-left"
                            v-if="['waiting_preference', 'documents_approved'].includes(student.status)">
                            <button class="btn btn-primary" @click="formShow('add')">
                                {{ $t("add_preference1") }}
                            </button>
                        </b-col>
                        <b-col cols="6" class="mb-4 mt-2"
                            v-if="preferences.length > 0 && student.status == 'waiting_preference'">
                            <button class="btn btn-primary float-right" v-if="!preferenceEditable" @click="sendPreference">
                                {{ $t("application_send") }}
                            </button>
                        </b-col>
                    </b-row>
                    <hr />
                </template>
            </template>

            <!-- Modals -->
            <CommonModal ref="preferenceModal" size="lg" :footer="true">
                <template v-slot:CommonModalTitle>
                    {{ $t("new").toUpper() }} {{ $t("preference").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <preferenceForm ref="preferenceDataForm" v-model="preference" />
                </template>
                <template v-slot:CommonModalFooter>
                    <button class="btn btn-primary" @click="preferenceSave">
                        {{ $t("add_preference1") }}
                    </button>
                </template>
            </CommonModal>
            <CommonModal ref="documentUploadModal" size="md" :onHideOnlyX="true" :footer="true"
                @bHideModalHeaderClose="preferenceFormClose()">
                <template v-slot:CommonModalTitle>
                    {{ $t("upload").toUpper() }}
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="documentValid">
                        <b-input-group class="mb-2">
                            <div class="label-as-input">
                                {{ isNotNullValue(document.name) }}
                            </div>
                        </b-input-group>
                        <ValidationProvider name="file" rules="required" v-slot="{ errors }">
                            <b-form-group :label="$t('select_file')">
                                <div class="d-flex custom-file-upload">
                                    <b-form-file v-model="document.file" :state="errors[0] ? false : null"
                                        accept="application/pdf" :placeholder="$t('select_file')"
                                        :drop-placeholder="$t('drop_file')" ref="fileInput"></b-form-file>
                                    <b-button variant="outline-secondary"
                                        @click="$refs.fileInput.$el.childNodes[0].click();">{{
                                            $t('browse') }}</b-button>
                                </div>
                                <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                            </b-form-group>
                        </ValidationProvider>
                    </ValidationObserver>
                </template>
                <template v-slot:CommonModalFooter>
                    <div class="d-flex">
                        <b-button variant="primary" @click="uploadDocument">
                            {{ $t('save') }}
                        </b-button>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

// Component
import Datatable from "@/components/datatable/Datatable";
import CommonModal from "@/components/elements/CommonModal";

// Page
import PreferenceForm from "./PreferenceForm";

// Service
import DoubleMajorApplicationService from "@/services/DoubleMajorApplicationService";

import { EventBus } from '@/main'

export default {
    name: "DoubleMajorApplicationStudent",
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        Datatable,
        CommonModal,
        PreferenceForm,
        doubleMajorPreference: () => import("./components/doubleMajorPreference"),
        preferenceForm: () => import("./components/preferenceForm"),
    },
    metaInfo() {
        return {
            title: this.$t("double_major_application"),
        };
    },
    data() {
        return {
            errMessage: "",
            loadingPage: false,
            loadingApplications: false,
            isNewButton: false,
            showExtraStudentInfo: false,
            student: {},
            loading: false,
            form: {},
            formId: null,
            formProcess: null,
            applications: [],
            applicationActive: false,
            document: {},
            documentOptions: [],
            preference: {},
            preferences: [],
            changePreferences: [],
            preferenceType: "",
            preferenceEditable: false,
            changePreference: [],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass: "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("your_preferences"),
                                class: "ri-edit-box-line",
                                callback: (row) => {
                                    this.preferenceFormShow(row);
                                },
                                show: (row) => {
                                    if (row.status == "waiting_preference") {
                                        return false;
                                    } else {
                                        return true;
                                    }
                                },
                            },
                            {
                                text: this.$t("edit_preference"),
                                class: "ri-edit-box-line",
                                callback: (row) => {
                                    this.preferenceFormShow(row);
                                },
                                show: (row) => {
                                    if (row.status == "waiting_preference") {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                callback: (row) => {
                                    this.delete(row.id);
                                },
                                show: (row) => {
                                    if (row.status == "waiting_preference") {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                },
                            },
                        ],
                    },
                    {
                        label: this.$t("id").toUpper(),
                        field: "id",
                        sortable: false,
                        hidden: true,
                        tdClass: "align-middle",
                    },
                    {
                        label:
                            this.$t("period").toUpper() +
                            " / " +
                            this.$t("semester").toUpper(),
                        field: "semester",
                        sortable: true,
                        hidden: false,
                        tdClass: "align-middle",
                        formatFn: (value, row) => {
                            return (
                                row.academic_year +
                                " / " +
                                this.getSemester(value)
                            );
                        },
                    },
                    {
                        label: this.$t("your_preferences").toUpper(),
                        field: "minor_application_preferences",
                        sortable: false,
                        hidden: false,
                        tdClass: "align-middle",
                        formatFn: (value) => {
                            let html = "";
                            if (value.length > 0) {
                                html += '<ol class="pl-2 m-0">';
                                value.forEach((item) => {
                                    html +=
                                        "<li>" +
                                        item.faculty_name +
                                        "<br>" +
                                        item.program_name +
                                        "</li>";
                                });
                                html += "</ol>";
                            }

                            return html;
                        },
                    },
                    {
                        label: this.$t("prep_score").toUpper(),
                        field: "prep_score",
                        sortable: false,
                        hidden: false,
                        tdClass: "align-middle",
                        formatFn: (value) => {
                            return value != null ? value : "-";
                        },
                    },
                    {
                        label: this.$t("result").toUpper(),
                        field: "approved_faculty",
                        sortable: false,
                        hidden: false,
                        tdClass: "align-middle",
                        formatFn: (value, row) => {
                            if (row.approved_faculty && row.approved_program) {
                                return (
                                    row.approved_faculty +
                                    "<br>" +
                                    row.approved_program
                                );
                            } else {
                                return "-";
                            }
                        },
                    },
                    {
                        label: this.$t("status").toUpper(),
                        field: "status",
                        sortable: false,
                        hidden: false,
                        tdClass: "align-middle",
                        formatFn: (value, row) => {
                            let variant = "badge-light";
                            if (value.includes("waiting"))
                                variant = "badge-warning";
                            else if (value == "declined")
                                variant = "badge-danger";
                            else if (value == "approved")
                                variant = "badge-success";

                            return (
                                '<span class="badge ' +
                                variant +
                                '">' +
                                row.status_text +
                                "</span>"
                            );
                        },
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    watch: {
        "student.status": {
            handler(val) {
                if (val) {
                    this.getPreferences();
                }
            },
        },
    },
    computed: {
        formKeyLength() {
            return Object.keys(this.student).length
        },
        documentWaiting() {
            let waiting = false
            for (const property in this.student.documents) {
                const document = this.student.documents[property]
                if (document.status == 'waiting') {
                    waiting = true
                }
            }
            return waiting
        },
        applicationDocuments() {
            return this.student.documents
        },
        preferenceOn() {
            let on = true
            const key = Object.keys(this.student.documents)

            for (let index = 0; index < key.length; index++) {
                const document = this.student.documents[key[index]];
                if (document?.status === 'waiting') {
                    on = false
                }
            }

            if (this.student.status == 'missing_document') {
                on = true
            }

            return on
        }
    },
    async created() {
        await this.checkDate();
        await this.showApplication();
    },
    methods: {
        async showDoc(file) {
            const type = file.name == 'ÖSYM Sonuç Belgesi' ? 'osym_result_document' : file.name == 'ÖSYM Yerleştirme Belgesi' ? 'osym_placement_document' : ''
            const res = await DoubleMajorApplicationService.downloadDocument(type).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );

            EventBus.$emit('pdfViewerModalShow', { pdfFileData: res.data, pdfFileName: file.filename });

            // this._downloadFile(res, file.filename)
        },
        async removeDoc(name) {
            const response = await this.$swal({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
            if (response.isConfirmed !== true) {
                return
            }


            const type = name == 'ÖSYM Sonuç Belgesi' ? 'osym_result_document' : name == 'ÖSYM Yerleştirme Belgesi' ? 'osym_placement_document' : ''
            const res = await DoubleMajorApplicationService.deleteDocument(type).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );
            if (res.data.success) {
                this.showApplication();
            }
            this.$toast.success(this.$t("api." + res.data.message));
        },
        async downloadDocument(type) {
            const response = await DoubleMajorApplicationService.downloadDocument(type).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );
            if (response.data.success) {
                // this._downloadFile(response, this.$t('student_document_create') + '.xlsx')
                // this.document = {}
                // this.showApplication();
                // this.$refs.documentUploadModal.$refs.commonModal.hide()
            }

            this.$toast.success(this.$t("api." + response.data.message));
        },
        async uploadDocument() {
            const valid = await this.$refs.documentValid.validate();
            if (!valid) {
                return
            }

            const formData = new FormData();
            formData.append("type", this.document.type);
            formData.append("file", this.document.file);
            const response = await DoubleMajorApplicationService.uploadDocument(formData).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );
            if (response.data.success) {
                this.document = {}
                this.showApplication();
                this.$refs.documentUploadModal.$refs.commonModal.hide()
                this.$refs.documentValid.reset();
            }

            this.$toast.success(this.$t("api." + response.data.message));
        },
        documentModalOpen(key, doc) {
            this.document.type = key
            this.document.name = this.getLocaleText(doc, 'name')
            this.$refs.documentUploadModal.$refs.commonModal.show()
        },
        async editableOn() {
            this.preferenceEditable = !this.preferenceEditable;

            if (!this.preferenceEditable) {
                if (this.changePreferences.length < 1) return;

                // const rank = this.changePreferences.map((item, index) => ({
                //     double_major_preference_id: item.id,
                //     rank: index + 1,
                // }))

                const rank = this.preferences.map((item) => ({
                    double_major_preference_id: item.id,
                    rank: item.rank,
                }));

                const response = await DoubleMajorApplicationService.changeRank({
                    rank: rank,
                }).catch((e) =>
                    this.$toast.error(this.$t("api." + e.data.message))
                );
                if (response.data.success) {
                    this.changePreferences = [];
                    this.getPreferences();
                }

                this.$toast.success(this.$t("api." + response.data.message));
            }
        },
        changeRank(e) {
            const data = { ...e };
            const index = this.preferences.findIndex(
                (item) => item.rank === data.new && item.id !== data.id
            );
            if (index > -1) {
                this.preferences[index].rank = data.old;
            }
        },
        async sendPreference() {
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_make_application"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                    icon: "question",
                })
                .then(async (result) => {
                    if (result.isConfirmed) {
                        const response =
                            await DoubleMajorApplicationService.savePreference().catch((e) =>
                                this.$toast.error(this.$t("api." + e.data.message))
                            );

                        if (response.data.success) {
                            this.showApplication();
                            this.getPreferences();
                        }
                        this.$toast.success(this.$t("api." + response.data.message));
                    }
                });
        },
        async preferenceSave() {
            const valid = await this.$refs["preferenceDataForm"].$refs["formModalValidate"].validate();
            if (!valid) {
                return;
            }

            const response = await DoubleMajorApplicationService.makePreference({
                program_code: this.preference.program_code,
                rank: this.preference.rank,
            }).catch((e) =>
                this.$toast.error(this.$t("api." + e.data.message))
            );

            if (response.data.success) {
                this.$refs.preferenceModal.$refs.commonModal.hide();
                this.$toast.success(this.$t("preference_add"));
                this.preference = {};
                this.getPreferences();
                this.$refs["preferenceDataForm"].$refs["formModalValidate"].reset();
            }
        },
        formShow(type) {
            this.$refs.preferenceModal.$refs.commonModal.show();
            this.preferenceType = type;
        },
        async getPreferences() {
            const response = await DoubleMajorApplicationService.getPreferences();
            if (response.data.success) {
                this.preferences = response.data.data;
                this.changePreferences = response.data.data;
            }
        },
        async start() {
            const response =
                await DoubleMajorApplicationService.makeApplication().catch((e) =>
                    this.$toast.error(this.$t("api." + e.data.message))
                );

            if (response.data.success) {
                await this.showApplication();
            }
            this.$toast.success(
                this.$t("documents_upload_pending")
            );
        },
        async showApplication() {
            const response =
                await DoubleMajorApplicationService.showApplication().catch(() => {
                    this.getForm();
                });


            if (response.data.success) {
                this.student = response.data.data;

                this.documentOptions = []
                for (const property in this.student.documents) {
                    const document = this.student.documents[property]
                    if (document.status == 'waiting') {
                        this.documentOptions.push({ text: this.getLocaleText(document, 'name'), value: property })
                    }

                }

            }
        },
        filter() {
            this.getRows();
        },
        filterClear() {
            this.datatable.queryParams.filter = {};
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            return DoubleMajorApplicationService.getAll()
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .then(() => {
                    this.isNewButton =
                        this.datatable.rows.filter((item) =>
                            item.status.includes("waiting")
                        ).length > 0
                            ? false
                            : true;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        async checkDate() {
            try {
                await DoubleMajorApplicationService.checkDate()
                this.applicationActive = true;
            } catch (error) {
                this.applicationActive = false;
                this.$toast.error(this.$t("undergraduate_check_date_message"))
            }
        },
        async getForm() {
            this.loadingPage = true;
            try {
                const response =
                    await DoubleMajorApplicationService.studentForm()
                        .catch((e) => {
                            this.errMessage = e.data.message
                            this.showErrors(e)
                        }).finally(() => {
                            this.loadingPage = false
                        });
                if (response.data.success) {
                    this.student = response.data.data
                }
            } catch (error) {
                this.showErrors(error)
            }
        },
        store() {
            DoubleMajorApplicationService.store()
                .then((response) => {
                    let data = response.data.data
                    this.preferenceFormShow(data)
                    this.getRows()
                })
                .catch((e) => {
                    this.showErrors(e)
                });
        },
        delete(id) {
            this.$swal
                .fire({
                    text: this.$t("are_you_sure_to_delete"),
                    showCancelButton: true,
                    confirmButtonText: this.$t("yes"),
                    cancelButtonText: this.$t("no"),
                    icon: "question",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        DoubleMajorApplicationService.del(id)
                            .then((response) => {
                                this.$toast.success(
                                    this.$t("api." + response.data.message)
                                );
                                this.getRows();
                            })
                            .catch((error) => {
                                this.$toast.error(
                                    this.$t("api." + error.data.message)
                                );
                            });
                    }
                });
        },

        // Form Clear
        formClear() {
            this.formData = null;
            this.formProcess = null;
        },
    },
};
</script>

<style lang="scss">
.preference-draggable {
    width: 100%;

    span {
        width: 100%;
        // display: flex;
        // flex-wrap: wrap;
    }
}
</style>
